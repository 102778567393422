.container {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #555;
  margin: 3px;
  cursor: pointer;
}

.disabled-item {
  color: rgb(177, 177, 177);
} 

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3px !important;
  margin-right: 10px;
  margin-left: 0;
  border: 1px solid #F6F6F6
}

.round {
  border-radius: 50% !important;
}

.fake-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #F6F6F6;
  border-radius: 3px;
  background-color: #F6F6F6;
}

.checkbox:checked {
  background-color: rgb(231, 85, 51);
  border: none
}

.container:hover {
  background: #f2f2f2;
}
