.group{
     background-color: blue;
    /*width: 10px;
    height: 100%; */
    width: 20px; 
    height: 20px;
    /* margin:30px; */
    border-radius: 25;
}
#test{
    margin-top: 5px;

}
.titleImg{
    flex: 1;
    height: 17px;
    width:17px;
    
}
.titleText{
    margin-left:15px;
   height: 100%;
   flex: 1;

}
.title{
  flex-direction: row;
  display: flex;
}
