.react-dropdown-select-dropdown {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
}

.react-dropdown-select-content {
  max-height: 132px;
  overflow-y: auto;
}

.react-dropdown-select-option {
  display: "flex" !important;
  flex-direction: "row" !important;
  align-items: "center" !important;
  justify-content: "space-between" !important;

  margin: 5px !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: rgb(246, 229, 226) !important;
}

.react-dropdown-select-option-label {
  color: rgb(216, 63, 40) !important;
  font-size: 14px !important;
}

.react-dropdown-select-option-remove {
  color: rgb(216, 63, 40) !important;
  font-size: 24px !important;
}
